@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '../../sparro-global/src/styles/main.scss';
//
// body {
//   margin     : 0;
//   font-family: "Roboto", sans-serif;
//   overflow-x : hidden;
// }
//
// .container-content {
//   margin-bottom: 20px;
//   padding-top  : 60px;
// }
//
// .block-button {
//   height: inherit;
//   width : 100%;
// }
//
// .mat-drawer-container {
//   background-color: #ffffff;
// }
//
// .bold {
//   font-family: inherit;
//   font-weight: 500;
// }
//
// .regular {
//   font-weight: 400;
//   font-family: inherit;
// }
//
// .light {
//   font-family: inherit;
//   font-weight: 300;
// }
//
// .font-xxs {
//   font-size: 12px;
// }
//
// .font-xs {
//   font-size: 14px;
// }
//
// .font-sm {
//   font-size: 16px;
// }
//
// .font-md {
//   font-size: 20px;
// }
//
// .font-lg {
//   font-size: 24px;
// }
//
// .font-head {
//   font-size: 32px;
// }
// /*********************** Text Utilities ************************ */
//
// .text-left {
//   text-align: left;
// }
//
// .text-right {
//   text-align: right;
// }
//
// .text-center {
//   text-align: center;
// }
//
// .text-justify {
//   text-align: justify;
// }
//
// .capitalize {
//   text-transform: capitalize;
// }
//
// .lowercase {
//   text-transform: lowercase;
// }
//
// .uppercase {
//   text-transform: uppercase;
// }
//
// .line-height {
//   line-height: 30px;
// }
// /********************** Font Utilities ***********************/
//
// .black-text {
//   color: #232323;
// }
//
// .white-text {
//   color: #ffffff;
// }
//
// .light-grey-text {
//   color: #eeeeee;
// }
//
// .grey-text {
//   color: #e6e6e6;
// }
//
// .dark-grey-text {
//   color: #707070;
// }
//
// .orange-text {
//   color: #f15a24;
// }
//
// .text-primary {
//   color: #232323;
// }
// /************************** Background Utilities***************/
//
// .black {
//   background-color: black;
// }
//
// .white {
//   background-color: #ffffff;
// }
//
// .light-grey {
//   background-color: #eeeeee;
// }
//
// .grey {
//   background-color: #e6e6e6;
// }
//
// .dark-grey {
//   background-color: #707070;
// }
//
// .orange {
//   background-color: #f15a24;
// }
// /******************** Spacing Utilities **********************/
//
// .mt-0 {
//   margin-top: 0;
// }
//
// .mb-0 {
//   margin-bottom: 0;
// }
//
// .mt-1 {
//   margin-top: 5px;
// }
//
// .mt-2 {
//   margin-top: 10px;
// }
//
// .mt-3 {
//   margin-top: 15px;
// }
//
// .m-0 {
//   margin: 0;
// }
//
// .m-2 {
//   margin: 8px;
// }
//
// .m-3 {
//   margin: 15px;
// }
//
// .mx-2 {
//   margin: 0 8px;
// }
//
// .my-2 {
//   margin: 8px 0;
// }
//
// .my-3 {
//   margin: 16px 0;
// }
//
// .p-0 {
//   padding: 0;
// }
//
// .pl-2 {
//   padding-left: 8px;
// }
//
// .pr-2 {
//   padding-right: 8px;
// }
//
// .p-1 {
//   padding: 2px;
// }
//
// .p-2 {
//   padding: 8px;
// }
//
// .p-3 {
//   padding: 16px;
// }
//
// .pt-2 {
//   padding-top: 5px;
// }
//
// .pt-3 {
//   padding-top: 16px;
// }
//
// .px-2 {
//   padding: 0 8px;
// }
//
// .py-2 {
//   padding: 8px 0;
// }
//
// .px-3 {
//   padding: 0 16px;
// }
//
// .py-3 {
//   padding: 16px 0;
// }
//
// .py-4 {
//   padding: 20px 0;
// }
//
// .spacing {
//   letter-spacing: 0.3px;
// }
// /********************* Width Utilities *********************/
//
// .width-25 {
//   width: 25%;
// }
//
// .width-50 {
//   width: 50%;
// }
//
// .width-75 {
//   width: 75%;
// }
//
// .width-100 {
//   width: 100%;
// }
//
// .height-100 {
//   height: 100%;
// }
// /***************** Border Utilities ******************/
//
// .rounded-border {
//   border-radius: 10px;
// }
//
// .no-border {
//   border-radius: 0;
// }
// /**************user avatar icons***************/
//
// .user-avatar {
//   height       : 40px;
//   width        : 40px;
//   border-radius: 50px;
//   margin-right : 15px;
// }
//
// .flex-grow {
//   flex: 1 0 auto;
// }
//
// ::ng-deep .mat-form-field-appearance-legacy .mat-form-field-infix {
//   border-top: 0;
// }
//
// ::ng-deep .mat-form-field-underline {
//   display      : none;
//   padding-right: 10px;
//   width        : 120px;
// }
