$scale: (
  "0": 0,
  "1": 0.25rem,
  "2": 0.5rem,
  "3": 0.75rem,
  "4": 1rem,
  "5": 1.25rem,
  "6": 1.5rem,
);

@each $point, $value in $scale {
  .top-#{$point} {
    top: $value;
  }
  .bottom-#{$point} {
    bottom: $value;
  }
  .left-#{$point} {
    left: $value;
  }
  .right-#{$point} {
    right: $value;
  }
}
