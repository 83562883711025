/***************************************************
**************** PRIMARY COLORS *******************
***************************************************/

$blue400        : #378ef0;
$blue500        : #2680eb;
$blue600        : #1473e6;
$blue700        : #0d66d0;

$grey100        : #f4f4f4;
$grey200        : #eeeeee;
$grey300        : #e6e6e6;
$grey400        : #d3d3d3;
$grey500        : #929292;
$grey550        : #828282;
$grey600        : #777777;
$grey700        : #707070;
$grey800        : #545454;
$grey850        : #333333;
$grey900        : #232323;

$green400       : #96d681;
$green500       : #71b564;
$green600       : #51a351;
$green700       : #3d994d;
$green800       : #24910A;

$orange400      : #ff8f40;
$orange500      : #fa7832;
$orange600      : #f15a24;
$orange700      : #d93b0b;
$orange800      : #cc3303;
$orange900      : #b42b00;

$red400         : #ec5b62;
$red500         : #e34850;
$red600         : #d7373f;
$red700         : #c9252d;

$white		    : #ffffff;
$lightgrey      : #fafafa;
$black		    : #000000;


/***************************************************
**************** SECONDARY COLORS ******************
***************************************************/

$indigo400      : #5c6bc0;
$indigo500      : #3f51b5;
$indigo600      : #3949ab;
$indigo700      : #303f9f;

$teal400        : #26a69a;
$teal500        : #009688;
$teal600        : #00897b;
$teal700        : #00796b;
$teal900        : #024737;

$purple400      : #ab47bc;
$purple500      : #9c27b0;
$purple600      : #8e24aa;
$purple700      : #7b1fa2;

$yellow400      : #ffff8d;
$yellow500      : #ffff00;
$yellow600      : #ffea00;
$yellow700      : #ffd600;
$yellow800      : #d6B606;
